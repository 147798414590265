<template>
  <div>
    <SwiperMain :items="swiperItems" class="" :allow-touch-move="false" :free-mode="false" :space-between="12">
      <template v-for="(item, index) in swiperItems" :key="index" v-slot:[`item-${index}`]>
        <img loading="lazy" :src="item.src" :alt="item.alt" class="object-contain h-20 w-full grayscale" :class="item.class" />
      </template>
    </SwiperMain>
  </div>
</template>

<script setup>
import SwiperMain from "@/components/animations/swiper/SwiperMain.vue";

// SwiperMain items
const swiperItems = [
  {src: '/img/anthropic.svg', alt: 'Anthropic Logo', class: 'object-contain h-20 w-full grayscale'},
  {src: '/img/aws.svg', alt: 'AWS Logo', class: 'object-contain h-20 w-full grayscale'},
  {src: '/img/digitalocean.svg', alt: 'DigitalOcean Logo', class: 'object-contain h-20 w-full grayscale'},
  {src: '/img/google.svg', alt: 'Google Logo', class: 'object-contain h-20 w-full grayscale'},
  {src: '/img/intelj.png', alt: 'Intel Logo', class: 'object-contain h-20 w-full grayscale'},
  {src: '/img/microsoft.svg', alt: 'Microsoft Logo', class: 'object-contain h-20 w-full grayscale'},
  {src: '/img/openai.svg', alt: 'OpenAI Logo', class: 'object-contain h-20 w-full grayscale'},
  {src: '/img/udemy.svg', alt: 'Udemy Logo', class: 'object-contain h-20 w-full grayscale'},
  {src: '/img/vuejs.svg', alt: 'Vue.js Logo', class: 'object-contain h-20 w-full grayscale'},
];
</script>

<style scoped>
</style>
