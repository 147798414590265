import { onMounted, onUnmounted } from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';

export function useAOS() {
	// Initialize AOS
	onMounted(() => {
		AOS.init({
			duration: 800,
			once: true,
			mirror: false,
		});
	});

	// Refresh AOS on route change
	const refreshAOS = () => {
		AOS.refresh();
	};

	// Clean up AOS on component unmount
	onUnmounted(() => {
		AOS.refresh();
	});

	return {
		refreshAOS,
	};
}
