<template>

  <div class="mr-5" style="position: fixed; right: 0px; bottom: 0px; width: 1%; z-index: 9;">
    <div class="mb-12  float-right">

      <!--  DESKTOP MENU (based on Menu State)-->
      <div class="">
        <div class="">
          <div class="flex h-full">
            <div class="hidden md:block">
              <div class="mx-4 flex justify-between h-full items-center">
                <router-link
                    class="transition text-black animate-bounce sticky content-center bg-tc-blue hover:bg-tc-blue btn btn-ghost btn-sm mx-4 p-6 rounded-btn"
                    to="/contact">
                  <ChatBubbleBottomCenterTextIcon class="h-6 w-6" aria-hidden="true"/>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  MOBILE MENU (based on Menu State)-->
      <div id="mobile-menu" class="md:hidden">
        <div class="mx-4 flex justify-between h-full items-center">
          <router-link
              class="transition text-black animate-bounce temporary-bounce sticky content-center bg-tc-blue hover:bg-tc-blue btn btn-ghost btn-sm mx-4 p-6 rounded-btn"
              to="/contact">
            <ChatBubbleBottomCenterTextIcon class="h-6 w-6" aria-hidden="true"/>
          </router-link>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import {ChatBubbleBottomCenterTextIcon} from '@heroicons/vue/24/outline'

export default {
  name: "Messenger",
  components: {
    ChatBubbleBottomCenterTextIcon
  },
}

</script>
<style>
.temporary-bounce {
  -webkit-animation-iteration-count: 10;
  animation-iteration-count: 10;
}
</style>
