

import animationData from './confetti.json';
import animationSound from './confetti.mp3';
import {defineComponent} from 'vue';
import LottieAnimation from '@/components/animations/Lottie/LottieAnimation.vue';

export default defineComponent({
	name: 'ConfettiBombAnimation',
	components: {LottieAnimation},
	props: {
		maxHeight: {
			type: String,
			default: '25em',
		},
		loop: {
			type: Boolean,
			default: false,
		},
		autoplay: {
			type: Boolean,
			default: true,
		},
		fullscreen: {
			type: Boolean,
			default: true,
		},
		fixed: {
			type: Boolean,
			default: true
		},
		width: {
			type: String,
			default: '300px',
		},
		height: {
			type: String,
			default: '300px',
		},
		speed: {
			type: String,
			default: '0.5',
		},
		soundEnabled: {
			type: Boolean,
			default: true,
		},
		startTime: {
			type: Number,
			default: 300
		},
	},
	setup() {
		return {
			animationData,
			animationSound
		}
	},
});
