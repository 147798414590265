<template id="app">
	<div style="padding-top: 8em;">
		<navbar></navbar>
		<Messenger></Messenger>
		<router-view/>
		<cookies-dialog></cookies-dialog>
		<Footer></Footer>
	</div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Messenger from "@/components/Messenger";
import CookiesDialog from "@/components/modal/CookiesDialog";

export default {
	components: {CookiesDialog, Messenger, Navbar, Footer}
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #141417;
}
</style>
