<template :class="templateCss">
	<div ref="carouselContainer" class="carousel-container relative">
		<!-- Left Shadow Overlay -->
		<div class="shadow-overlay-left"></div>

		<!-- Right Shadow Overlay -->
		<div class="shadow-overlay-right"></div>

		<div :class="padding" v-if="headerTextActive">
			<div :class="margin">
				<div class="flex-col mb-20 flex">
					<h2 class="text-white text-center text-4xl xl:text-5xl">{{ headerText }}</h2>
					<p v-if="descriptionText" class="mt-2 text-md text-center text-gray-300">{{ descriptionText }}</p>
				</div>
			</div>
		</div>

		<Swiper
				:modules="[Navigation, Pagination, Autoplay, Mousewheel, FreeMode]"
				:mousewheel="{ releaseOnEdges: true }"
				:spaceBetween="spaceBetween"
				:centeredSlides="false"
				:stopOnLastSlide="false"
				:waitForTransition="true"
				:direction="direction"
				:loop="loop"
				:autoplay="autoplay"
				:speed="speed"
				:pagination="{
        clickable: true,
        el: '.swiper-pagination',
      }"
				:navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
				:slidesPerView="'auto'"
				:freeMode="freeMode"
				:allowTouchMove="allowTouchMove"
				class="px-0 mySwiper"
		>
			<SwiperSlide v-for="(slide, index) in slides" :key="index" :class="[swiperSlideCss]">
				<Card v-if="useCardComponent" :btn-color-app="slide.colorBtnApp"
					  :btn-color-service="slide.colorBtnService"
					  class="text-left"
					  :id="slide.id"
					  :picture-src2-active="slide.pictureSrc2Active"
					  :card-button-link-active="slide.cardButtonLinkActive"
					  :card-button="t(slide.cardButton)"
					  :card-button-link="slide.cardButtonLink"
					  :card-description="t(slide.cardDescription)"
					  :card-title="t(slide.cardTitle)"
					  :picture-src="slide.pictureSrc"
					  :picture-src2="slide.pictureSrc2"
				/>
			</SwiperSlide>
		</Swiper>
	</div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import {Navigation, Pagination, Autoplay, Mousewheel, FreeMode} from 'swiper/modules';
import Card from "@/components/card/Card.vue";
import useTranslation from "@/composable/core/useTranslation";
import {computed} from "vue";

export default {
	name: "Carousel",
	components: {
		Card,
		Swiper,
		SwiperSlide
	},
	props: {
		headerText: {
			type: String,
			default: "",
		},
		descriptionText: {
			type: String,
			default: "",
		},
		headerTextActive: {
			type: Boolean,
			default: false,
		},
		changeDirection: {
			type: Boolean,
			default: false,
		},
		direction: {
			type: String,
			default: 'horizontal', // can be 'vertical'
		},
		templateCss: {
			type: String,
			default: 'mb-20',
		},
		padding: {
			type: String,
			default: 'pt-20 px-8 pb-5',
		},
		margin: {
			type: String,
			default: 'mb-6',
		},
		swiperSlideCss: {
			type: String,
			default: 'space-x-4',
		},
		freeMode: {
			type: Boolean,
			default: true,
		},
    allowTouchMove: {
      type: Boolean,
      default: true,
    },
		loop: {
			type: Boolean,
			default: false,
		},
		spaceBetween: {
			type: Number,
			default: 5,
		},
		speed: {
			type: Number,
			default: 25000,
		},
		useCardComponent: {
			type: Boolean,
			default: true,
		},
		slides: {
			type: Array,
			default: () => [],
		}
	},
	setup(props) {
		const {t} = useTranslation();

		// Dynamische Konfiguration des Autoplay-Props basierend auf `changeDirection`
		const autoplay = computed(() => ({
			delay: 1,
			disableOnInteraction: false,
			reverseDirection: props.changeDirection,
			pauseOnMouseEnter: true,
		}));

		return {
			t,
			Navigation,
			Pagination,
			Autoplay,
			Mousewheel,
			FreeMode,
			autoplay
		};
	}
};
</script>

<style scoped>
.carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.shadow-overlay-left,
.shadow-overlay-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20%; /* Adjust to change the shadow width */
    pointer-events: none;
    z-index: 2;
}

/*.shadow-overlay-left {
  left: 0;
  background: linear-gradient(to right, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
}

.shadow-overlay-right {
  right: 0;
  background: linear-gradient(to left, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9) 40%, rgba(0,0,0,0) 100%);
}*/

.swiper {
    width: 100%;
    height: 100%;
    overflow: visible;
}

.swiper-slide {
    width: auto;
    max-width: 23em;
    height: auto;
}

@media (min-width: 640px) {
    .swiper-slide {
        width: calc(50% - 15px);
    }
}

@media (min-width: 1024px) {
    .swiper-slide {
        width: calc(33.333% - 20px);
    }
}

.swiper-button-prev,
.swiper-button-next {
    background-color: rgba(255, 255, 255, 0.5);
    color: #000;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 20px;
}

.swiper-pagination-bullet {
    background-color: #fff;
    opacity: 0.5;
}

.swiper-pagination-bullet-active {
    opacity: 1;
}
</style>
