
import {ref, computed, defineComponent, defineAsyncComponent, onMounted} from 'vue';
import {AbstractWizzard} from '@/theconcept/wizzard/base/abstract_wizzard';
import ConfettiBombAnimation from '@/components/animations/Lottie/ConfettiBombAnimation/ConfettiBombAnimation.vue';
import {OfferService} from "@/theconcept/api/offer/offer.service";
import useTranslation from "@/composable/core/useTranslation";

export default defineComponent({
	name: 'WizzardComponent',
	components: {ConfettiBombAnimation},
	props: {
		wizzard: {
			type: Object as () => AbstractWizzard,
			required: true,
		},
	},
	emits: ['wizzard-show-summary', 'wizzard-last-step', 'wizzard-finished', 'wizard-cancelled', 'wizard-reset'],
	setup(props, {emit}) {
    const {t} = useTranslation();
    const wizzardObject = ref<AbstractWizzard>(props.wizzard);
		const currentStepObject = ref();
		const showFinishedAnimation = ref(false);
		const isStepComponentLoaded = ref(false);
		const currentStepComponent = ref<any>(null);
		const isSummaryComponentLoaded = ref(false);
		const summaryComponent = ref<any>(null);


		const stepCount = computed(() => {
			return wizzardObject.value.getStepsCount();
		});

		const wizzardName = computed(() => {
			return wizzardObject.value.getWizzardName();
		});

		const wizzardColor = computed(() => {
			return wizzardObject.value.getWizzardColor();
		});

		const wizzardPrimaryTextColor = computed(() => {
			return wizzardObject.value.getWizzardPrimaryTextColor();
		});

		const wizzardDefaultStepTextColor = computed(() => {
			return wizzardObject.value.getWizzardDefaultStepTextColor();
		});

		const currentStep = computed(() => {
			return wizzardObject.value.getCurrentStep();
		});

		const isPrevStepAllowed = computed(() => {
			return wizzardObject.value.isPreviousStepAllowed() || isSummaryComponentLoaded.value;
		});

		const isNextStepAllowed = computed(() => {
			return wizzardObject.value.isNextStepAllowed();
		});

		const isNextStepEnabled = computed(() => {
			return wizzardObject.value.isNextStepEnabled;
		});

		const isLastStep = computed(() => {
			return wizzardObject.value.isLastStep();
		});

		const stepStatusInfo = computed(() => {
			return wizzardObject.value.getStepsStatusInfo();
		});

		const currentStepStatusInfo = computed(() => {
			return wizzardObject.value.getStepStatusInfo(currentStep.value);
		});

		const progressBarWidth = computed(() => {
			return wizzardObject.value.getStepProgressInPercent() + '%';
		});

		const goToStep = (step: number) => {
			wizzardObject.value.goToStep(step);
			loadComponent();
		};

		const loadComponent = async () => {
			// Dynamically import the component
			isStepComponentLoaded.value = false;
			isSummaryComponentLoaded.value = false;
			summaryComponent.value = null;
			const currentStepComponentName = wizzardObject.value.getCurrentStepComponent();
			if (!currentStepComponentName) {
				return;
			}

			let componentPath;
			if (currentStepComponentName.startsWith('base/')) {
				componentPath = `./${currentStepComponentName}.vue`;
			} else {
				componentPath = `./${wizzardObject.value.getStepsPath()}/${currentStepComponentName}.vue`;
			}

      currentStepComponent.value = defineAsyncComponent(() => import(`${componentPath}`));
			isStepComponentLoaded.value = true;
		};

		const loadSummaryComponent = async () => {
			// Dynamically import the component
			isStepComponentLoaded.value = false;
			isSummaryComponentLoaded.value = false;
			currentStepComponent.value = null;
			const wizzardSummaryComponent = wizzardObject.value.getWizzardSummaryComponent();
			if (!wizzardSummaryComponent) {
				return;
			}
			const componentPath = `./${wizzardObject.value.getWizzardSummaryBasePath()}/${wizzardSummaryComponent}.vue`;
			summaryComponent.value = defineAsyncComponent(() => import(`${componentPath}`));
			isSummaryComponentLoaded.value = true;
		};


    const sendRequest = () => {
      const data = wizzardObject.value.serializeForApiRequest();
      return OfferService.create(data).then(() => {
            return true
          },
          error => {
            console.log(error);
            return false
          });
    }

		const finishWizzard = () => {
      sendRequest().then((result) => {
        if (!result) {
          //TODO show error
          return;
        }
        wizzardObject.value.markWizzardAsDone();
        showFinishedAnimation.value = true;
        if (wizzardObject.value.autoCloseWizzardAfterLastStep) {
          setTimeout(() => {
            loadComponent();
            emit('wizzard-finished');
          }, 2000);
        } else {
          emit('wizzard-finished');
          setTimeout(() => {
            closeWizzard();
          }, 15000)
        }
      })
		};

		const showSummary = () => {
			wizzardObject.value.goToSummaryStep();
			emit('wizzard-last-step');
			loadSummaryComponent();
			emit('wizzard-show-summary');
		};

		const nextStep = () => {
			const nextStep = wizzardObject.value.goToNextStep();
			if (!nextStep) {
				emit('wizzard-last-step');
			} else {
				loadComponent();
			}
		};

    const closeWizzard = () => {
      emit('wizard-reset');
    }

    //TODO send request, check multi checkbox services, Summary fix

		const prevStep = () => {
			if (isSummaryComponentLoaded.value) {
				goToStep(currentStep.value);
			} else {
				const previousStep = wizzardObject.value.goToPreviousStep();
				if (!previousStep) {
					emit('wizard-cancelled');
				} else {
					loadComponent();
				}
			}
		};

		onMounted(() => {
			//TODO load step or component by path
			loadComponent();
		});

		return {
      t,
			stepCount,
			currentStep,
			currentStepObject,
			nextStep,
			prevStep,
			showSummary,
			wizzardColor,
			wizzardPrimaryTextColor,
      wizzardDefaultStepTextColor,
			wizzardName,
			isPrevStepAllowed,
			isNextStepAllowed,
			stepStatusInfo,
			currentStepStatusInfo,
			wizzardObject,
			currentStepComponent,
			isStepComponentLoaded,
			progressBarWidth,
			isLastStep,
			finishWizzard,
			isSummaryComponentLoaded,
			summaryComponent,
			goToStep,
			showFinishedAnimation,
			isNextStepEnabled,
      closeWizzard,
		};
	},
});
