import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LottieAnimation = _resolveComponent("LottieAnimation")!

  return (_openBlock(), _createBlock(_component_LottieAnimation, {
    animationData: _ctx.animationData,
    loop: _ctx.loop,
    autoplay: _ctx.autoplay,
    fixed: _ctx.fixed,
    fullscreen: _ctx.fullscreen,
    width: _ctx.width,
    height: _ctx.height,
    "start-time": _ctx.startTime,
    speed: _ctx.speed,
    "z-index": 9999,
    "sound-enabled": _ctx.soundEnabled,
    "sound-src": _ctx.animationSound
  }, null, 8, ["animationData", "loop", "autoplay", "fixed", "fullscreen", "width", "height", "start-time", "speed", "sound-enabled", "sound-src"]))
}